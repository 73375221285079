.contact {
  background: rgba(71, 207, 241, 0.546);
  transition: all 0.22s ease-in-out;
  box-shadow: 0 8px 62px 0 rgba(0, 17, 255, 0.37);
  backdrop-filter: blur(1px);
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  padding: 80px 40px;
  width: 800px;
  height: 700px;
  margin-top: 40px;
}

.contact:hover {
  transform: scale(1.03);
}

.main-contact {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
}

.contact-div .title-h4,
.follow-div .title-h4 {
  color: rgb(253, 110, 110);
  font-family: "Syne Mono", monospace;
  text-decoration: underline;
  font-size: 30px;
  padding-bottom: 20px;
}

.certs-contact-me .title-h3,
.certs-contact-me .title-h4 {
  color: rgb(253, 110, 110);
  font-family: "Syne Mono", monospace;
  text-decoration: underline;
  font-size: 25px;
}

.icons {
  width: 60px;
  padding: 40px 5px;
}

.follow-div ul {
  padding: 0;
}

.follow-div ul li {
  list-style: none;
  padding: 28px 0;
}

.follow-div ul li a {
  color: #243b46;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

::placeholder {
  text-align: center;
  color: rgb(214, 214, 214);
}

input,
textarea {
  padding: 5px 0;
  background-color: rgb(53, 138, 184);
  border: none;
  color: rgb(214, 214, 214);
}

input {
  height: 30px;
}

label {
  margin: 5px 0;
}

.submit {
  border-color: rgb(253, 110, 110);
  border-width: 0.5px;
  background-color: rgb(114, 173, 209);
  color: white;
  font-family: "Syne Mono", monospace;
  box-shadow: 1px 1px 1px 1px rgb(253, 110, 110);
  width: 70px;
  font-size: 12px;
  margin-top: 10px;
  margin-bottom: 10px;
}

@media only screen and (max-width: 1300px) {
  .contact {
    padding: 80px 40px;
    width: 750px;
    height: 680px;
    margin-top: 20px;
  }
  .contact-div .title-h4,
  .follow-div .title-h4 {
    font-size: 28px;
  }
  .certs-contact-me .title-h3,
  .certs-contact-me .title-h4 {
    font-size: 22px;
  }
  input {
    height: 26px;
  }
}

@media only screen and (max-width: 800px) {
  .contact {
    padding: 40px 40px;
    width: 450px;
    height: 480px;
  }
  .contact-div .title-h4,
  .follow-div .title-h4 {
    font-size: 18px;
  }
  .certs-contact-me .title-h3,
  .certs-contact-me .title-h4 {
    font-size: 14px;
  }
  .icons {
    width: 32px;
    padding: 20px 5px;
  }
  input,
  textarea {
    padding: 0;
  }

  label {
    font-size: 14px;
  }
  ::placeholder {
    font-size: 14px;
  }
  .follow-div ul li a {
    font-size: 14px;
  }
  .follow-div ul li {
    padding: 22px 0;
  }
  input {
    height: 22px;
  }
}

@media only screen and (max-width: 550px) {
  .contact {
    padding: 40px 40px;
    width: 340px;
    height: 450px;
  }
  .contact-div .title-h4,
  .follow-div .title-h4 {
    font-size: 16px;
    padding-bottom: 10px;
  }
  .certs-contact-me .title-h3,
  .certs-contact-me .title-h4 {
    font-size: 14px;
  }
  .icons {
    width: 22px;
    padding: 20px 2px;
  }
  input,
  textarea {
    padding: 0;
    width: 130px;
  }
  input {
    height: 16px;
  }
  label {
    font-size: 12px;
  }
  ::placeholder {
    font-size: 12px;
  }
  .follow-div ul li a {
    font-size: 12px;
  }
  .follow-div ul li {
    padding: 18px 0;
  }
}

.about {
  display: flex;
  align-items: center;
  background: rgba(71, 207, 241, 0.546);
  transition: all 0.22s ease-in-out;
  box-shadow: 0 8px 62px 0 rgba(0, 17, 255, 0.37);
  backdrop-filter: blur(1px);
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  padding: 200px 40px;
  width: 800px;
  height: 700px;
  margin-top: 40px;
}

.about:hover {
  transform: scale(1.03);
}

.about-img .title-h3 {
  color: rgb(253, 110, 110);
  font-family: "Syne Mono", monospace;
  text-decoration: underline;
  position: relative;
  bottom: 10px;
}

.personal-img {
  width: 250px;
  border-radius: 4px;
  border: rgb(253, 110, 110) solid 4px;
  border-radius: 2px;
  padding: 5px;
}

.about-desc {
  color: white;
  padding: 20px;
}

.download {
  border-color: rgb(253, 110, 110);
  border-width: 0.5px;
  background-color: rgb(114, 173, 209);
  color: white;
  font-family: "Syne Mono", monospace;
  box-shadow: 2px 2px 2px 2px rgb(253, 110, 110);
  padding: 5px 5px;
}

@media only screen and (max-width: 1300px) {
  .about {
    padding: 40px 20px 40px 20px;
    width: 750px;
    height: auto;
    margin-top: 20px;
  }
}

@media only screen and (max-width: 800px) {
  .about {
    width: 450px;
    height: 500px;
    padding: 90px 10px 65px 10px;
  }

  .about-img .title-h3 {
    font-size: 18px;
  }

  .personal-img {
    width: 150px;
  }

  .about-desc {
    font-size: 12px;
  }
}

@media only screen and (max-width: 550px) {
  .about {
    width: 340px;
    height: 460px;
    padding: 90px 10px 45px 10px;
  }

  .about-img .title-h3 {
    font-size: 16px;
  }

  .personal-img {
    width: 120px;
  }

  .about-desc {
    font-size: 10px;
  }
}

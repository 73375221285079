@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,700;1,200&family=Syne+Mono&display=swap");

.main-app {
  text-align: center;
  background-color: rgb(0, 11, 40);
  background-repeat: no-repeat;
  background-size: cover;
  font-family: "Nunito Sans", sans-serif;
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
  padding: 10px 10px;
}

.App {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 0 40px 0;
}

h1 {
  font-family: "Syne Mono", monospace;
}

@media only screen and (max-width: 1300px) {
  .App {
    display: flex;
    flex-direction: column;
  }
}

.main-link {
  text-decoration: none;
}

.main-link h1 {
  font-size: 34px;
  padding-top: 20px;
  color: rgb(253, 110, 110);
}

@media only screen and (max-width: 1300px) {
  .main-link h1 {
    font-size: 26px;
  }
}

@media only screen and (max-width: 800px) {
  .main-link h1 {
    font-size: 20px;
  }
}

@media only screen and (max-width: 550px) {
  .main-link h1 {
    font-size: 14px;
  }
}

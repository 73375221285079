small {
  color: white;
  text-shadow: 1px 1px rgb(79, 129, 152);
}

@media only screen and (max-width: 800px) {
  small {
    font-size: 12px !important;
  }
}

@media only screen and (max-width: 550px) {
  small {
    font-size: 10px !important;
  }
}

.navbar {
  background-color: rgb(253, 110, 110);
  margin: 0 10px;
  height: 500px;
  width: 200px;
  border-radius: 20px;
  margin-right: 40px;
}

.navbar ul {
  width: 100%;
  padding: 0;
  margin: 0;
}
.navbar ul li {
  list-style: none;
  padding: 40px 20px;
}

.navbar ul li a {
  color: white;
  font-weight: 700;
  font-size: 18px;
}

@media only screen and (max-width: 1300px) {
  .navbar {
    margin: 10px 10px;
    height: 50px;
    width: 500px;
  }

  .navbar ul li {
    display: inline;
  }

  .navbar ul li a {
    font-size: 16px;
  }
}

@media only screen and (max-width: 800px) {
  .navbar {
    margin: 5px 5px;
    height: 50px;
    width: 300px;
  }

  .navbar ul li {
    padding-left: 10px;
    padding-right: 10px;
  }

  .navbar ul li a {
    font-size: 12px;
  }
}

@media only screen and (max-width: 550px) {
  .navbar {
    height: 50px;
    width: 280px;
  }

  .navbar ul li {
    padding-left: 5px;
    padding-right: 5px;
  }
}

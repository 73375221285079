.carousel-slider {
  background: rgba(71, 207, 241, 0.546);
  transition: all 0.22s ease-in-out;
  box-shadow: 0 8px 62px 0 rgba(0, 17, 255, 0.37);
  backdrop-filter: blur(1px);
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  padding: 200px 40px;
  width: 800px;
  height: 700px;
  margin-top: 40px;
}
.carousel-slider:hover {
  transform: scale(1.03);
}

.carousel {
  bottom: 145px;
}

.carousel-slider .title-h3 {
  position: relative;
  bottom: 170px;
  color: rgb(253, 110, 110);
  font-family: "Syne Mono", monospace;
  text-decoration: underline;
  font-size: 30px;
}

.item a img {
  width: 400px;
  border-radius: 20px;
}

.caption {
  background-color: rgb(253, 110, 110);
  font-size: 10px;
  border-radius: 10px;
}
.caption-h3 {
  font-size: 14px;
}

.hide {
  display: none;
}

@media only screen and (max-width: 1300px) {
  .carousel-slider {
    width: 750px;
    height: 680px;
    margin-top: 20px;
    padding: 0px 40px 0px 40px;
  }
  .carousel-slider .title-h3 {
    font-size: 28px;
    bottom: 0px;
    padding-top: 30px;
    padding-bottom: 20px;
  }
  .carousel {
    bottom: 40px;
    top: 0px;
    padding-bottom: 60px;
  }
}

@media only screen and (max-width: 800px) {
  .carousel-slider {
    padding: 10px 40px;
    width: 450px;
    height: 630px;
  }
  .carousel-slider .title-h3 {
    font-size: 18px;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .carousel {
    padding-bottom: 20px;
  }
  .caption {
    font-size: 8px;
    visibility: hidden;
  }
  .caption:hover {
    visibility: visible;
  }
  .caption-h3 {
    font-size: 12px;
  }
  .hide {
    display: block;
    background-color: rgb(253, 110, 110);
    color: white;
    font-size: 12px;
    margin-top: 5px;
    padding: 10px;
    border-radius: 20px;
  }
  .carousel-control-next-icon {
    position: relative;
    left: 50px;
  }

  .carousel-control-prev-icon {
    position: relative;
    right: 50px;
  }
}

@media only screen and (max-width: 550px) {
  .carousel-slider {
    width: 340px;
    height: 600px;
    padding: 0px 10px 45px 10px;
  }
  .carousel-slider .title-h3 {
    font-size: 16px;
  }
  .caption {
    font-size: 8px;
    visibility: hidden;
  }
  .caption:hover {
    visibility: visible;
  }
  .caption-h3 {
    font-size: 12px;
  }
  .carousel-control-next-icon {
    background-image: none !important;
  }

  .carousel-control-prev-icon {
    background-image: none !important;
  }
}
